define("ember-quickstart/components/letter-box", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    letter: null,
    dayWord: null,
    index: null,
    init() {
      this._super(...arguments);
    },
    get isLetterContained() {
      return !this.isLetterOnRightPlace && this.dayWord?.includes(this.letter);
    },
    get isLetterOnRightPlace() {
      return this.dayWord ? this.dayWord[this.index] === this.letter : null;
    },
    get boxColor() {
      if (this.isLetterOnRightPlace) {
        return 'green';
      } else if (this.isLetterContained) {
        return 'orange';
      }
      return 'blue';
    }
  });
  _exports.default = _default;
});