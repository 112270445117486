define("ember-quickstart/templates/components/word-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "CINgAFYA",
    "block": "{\"symbols\":[\"letter\",\"index\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"word-row\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"typedLettersArray\"]]],null,{\"statements\":[[0,\"    \"],[5,\"letter-box\",[],[[\"@letter\",\"@dayWord\",\"@index\"],[[23,1,[]],[23,0,[\"dayWord\"]],[23,2,[]]]]],[0,\"\\n\"]],\"parameters\":[1,2]},null],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-quickstart/templates/components/word-row.hbs"
    }
  });
  _exports.default = _default;
});