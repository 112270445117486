define("ember-quickstart/templates/components/main-game", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fqmvt0Fm",
    "block": "{\"symbols\":[\"try\",\"index\"],\"statements\":[[7,\"div\",true],[10,\"class\",\"main-area\"],[8],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"title\"],[8],[0,\"\\n    \"],[7,\"h1\",true],[8],[0,\"weirdle. 🧑‍🎤\"],[9],[0,\"\\n    \"],[7,\"h6\",true],[8],[0,\"just another wordle game.\"],[9],[0,\"\\n  \"],[9],[0,\"\\n\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"game\"],[8],[0,\"\\n\"],[4,\"each\",[[23,0,[\"triesCount\"]]],null,{\"statements\":[[4,\"if\",[[28,\"lte\",[[23,2,[]],5],null]],null,{\"statements\":[[0,\"        \"],[5,\"word-row\",[],[[\"@dayWord\",\"@inputWord\",\"@isGameFinished\",\"@notEnoughLetters\"],[[23,0,[\"dayWord\"]],[23,0,[\"inputWord\"]],[23,0,[\"isGameFinished\"]],[23,0,[\"notEnoughLetters\"]]]]],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[1,2]},null],[0,\"  \"],[9],[0,\"\\n\\n\"],[4,\"if\",[[23,0,[\"isGameFinishedRight\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h3\",true],[8],[0,\"Congratulations, you got the word right! 🥳 \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[4,\"if\",[[23,0,[\"isGameFinishedWrong\"]]],null,{\"statements\":[[0,\"    \"],[7,\"h3\",true],[8],[0,\"Oops, it seems you do not got the right word! 😢 \"],[9],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n  \"],[7,\"div\",true],[10,\"class\",\"notifications\"],[8],[0,\"\\n    \"],[1,[23,0,[\"notificationMessage\"]],false],[0,\"\\n  \"],[9],[0,\"\\n\\n\\n  \"],[7,\"div\",true],[10,\"class\",\"footer\"],[8],[0,\"\\n    \"],[7,\"small\",true],[8],[0,\"\\n      by guilmour\\n    \"],[9],[0,\"\\n  \"],[9],[0,\"\\n\"],[9]],\"hasEval\":false}",
    "meta": {
      "moduleName": "ember-quickstart/templates/components/main-game.hbs"
    }
  });
  _exports.default = _default;
});