define("ember-quickstart/components/day-word", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    dayWord: "Hi",
    init() {
      this._super(...arguments);
      this.getWords();
    },
    async getWords() {
      let randomNumber = Math.floor(Math.random() * 340);
      const response = await fetch('https://challenge.trio.dev/api/v1/wordle-words');
      let words = await response.json();
      this.set('dayWord', words[randomNumber]);
    }
  });
  _exports.default = _default;
});